import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import { GetTitleRequest, DeleteTitleRequest } from '../../../redux/reducer/FetchCompanyDataReducer'
import Loader from '../../../utils/Loader'
import Swal from "sweetalert2";
import ApiConnection from '../../../utils/ApiConnection'

const ViewEmailTemplate = () => {

    const [subjectList, setsubjectList] = useState([])
    const [msgtype, setmsgtype] = useState("")

    const fetchacompanyReducer = useSelector((state) => state.FetchCompanyDataReducer)
    var userinfo = JSON.parse(localStorage.getItem('userinfo'))
    var groupList = fetchacompanyReducer?.GetTitleResponse?.results

    console.log(fetchacompanyReducer.GetTitleResponse)

    const dispatch = useDispatch()
    let navigate = useNavigate()

    useEffect(() => {
        GetEmailList()
    }, [])

    const GetEmailList = async () => {

        try {
            const response = await ApiConnection.get(`template-crud/?server_type=production`)
            if (response?.status == 200) {
                setsubjectList(response?.data?.results)
            }
        } catch (e) { }
    }

    const handleMsgTypeChange = async (e) => {

        var val = e.target.value
        setmsgtype(val)

        try {
            const response = await ApiConnection.get(`template-crud/?server_type=production&template_type=${val}`)
            if (response?.status == 200) {
                setsubjectList(response?.data?.results)
            }
        } catch (e) { }
    }

    // useEffect(() => {
    //     dispatch(GetTitleRequest())
    // }, [fetchacompanyReducer?.DeleteTitleResponse])


    const DeleteHandle = async (id, Delid) => {

        try {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await ApiConnection.put(`template-crud/?server_type=production&id=${id}&method=delete`)
                    if (response?.status == 200) {
                        Swal.fire(
                            'Deleted!',
                            'Your file has been deleted.',
                            'success'
                        )
                        GetEmailList()
                    }
                }
            })
        } catch (e) { }
    }





    return (
        <div className='common-layout'>
            <Loader visible={fetchacompanyReducer.isloading} />
            <div className='innerlayout p-3'>
                <div className='mb-4'>
                    <h3> Message Template Management</h3>
                </div>

                <div className='button-part mb-4' style={{ marginLeft: '910px', marginTop: '-67px' }}>
                    <ul>
                        <li>
                            <button className='formBtn1' onClick={() => navigate(-1)}><i className="fa-solid fa-angle-left"></i> Back</button>
                        </li>
                    </ul>
                </div>

                <div className='button-part mb-4'>

                    <ul>
                        <div className='col-lg-3 '>
                            <div className='form-group'>
                                <label className='selectlabel'>Template Type</label>
                                <select
                                    className='form-control'  style={{fontSize: '14px'}}
                                  onChange={(e) => handleMsgTypeChange(e)}
                                >
                                    <option value="">--Select Template Type---</option>
                                    <option value="email">Email</option>
                                    <option value="sms">SMS</option>
                                </select>
                            </div>
                        </div>
                        {/* <li>
                            <NavLink className='formBtn1' style={{marginTop: '14px'}} to={`/${userinfo?.company_slug}/settings/EmailTemplate/AddEmailTemplate`}><i className="fa-solid fa-plus"></i> Add Message Template</NavLink>
                        </li> */}
                    </ul>
                </div>

                <div className='table-responsive'>
                    <table className='custom-table'>
                        <thead>
                            <tr>
                                <th>Message Type</th>
                                <th>Template Type</th>
                                <th>Subject</th>
                                <th>Message Body</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {subjectList && subjectList.length > 0 ? (
                                subjectList.map((item, i) => (
                                    <tr key={i}>
                                        <td>{item?.msg_type_name}</td>
                                        <td>{item?.template_type}</td>
                                        <td>{item?.email_subject}</td>
                                        <td>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: item?.msg_body?.replace(/<p>/g, '')?.replace(/<\/p>/g, ''),
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <NavLink to={`/${userinfo?.company_slug}/settings/EmailTemplate/EditEmailTemplate/${item?.id}`}>
                                                <i className="fa-solid fa-pen-to-square"></i>
                                            </NavLink>
                                            <button onClick={() => DeleteHandle(item.id)}>
                                                <i className="fa-solid fa-trash"></i>
                                            </button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="text-center">No data found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>


            </div>

        </div>
    )
}

export default ViewEmailTemplate