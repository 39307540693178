import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import Accordion from 'react-bootstrap/Accordion';
import { useDispatch, useSelector } from 'react-redux'
import ApiConnection from '../../utils/ApiConnection'

const CompanySidebar = () => {
  var userinfo = JSON.parse(localStorage.getItem('userinfo'))
  const sidebarReducer = useSelector((state) => state.sidebarSlice?.isSidebarshow)

  console.log(sidebarReducer)

  const [checked, setChecked] = useState({});
  const [Sidemenus, setSidemenus] = useState([])

  var DynmicClass = ""

  if (sidebarReducer === true) {
    DynmicClass = ""
  }
  if (sidebarReducer === false) {
    DynmicClass = "closes"
  }


  useEffect(() => {
    if (userinfo?.user_type) {
      GetTermsofServices(userinfo?.user_type);
    }
  }, [userinfo?.user_type]);
  
  const GetTermsofServices = async (usertype) => {
    try {
      const response = await ApiConnection.get(`users/menu-submenu-tabmenu-crud/api/?server_type=production&user_type=${userinfo?.user_type}`);
      if (response?.status === 200) {
        setSidemenus(response?.data?.results);
        const initialChecked = {};
        response?.data?.results.forEach(item => {
          initialChecked[item.id] = ensureAllPermissions(item.menu_user_permission_list);
  
          item.tab_menu_list.forEach(tab => {
            if (!initialChecked[item.id]) initialChecked[item.id] = {};
            initialChecked[item.id][tab.tab_menu_id] = ensureAllPermissions(tab.tab_menu_user_permission_list);
          });
  
          item.sub_menu_list.forEach(sub => {
            if (!initialChecked[item.id]) initialChecked[item.id] = {};
            initialChecked[item.id][sub.sub_menu_id] = ensureAllPermissions(sub.sub_menu_user_permission_list);
  
            sub.sub_tab_menu_list.forEach(subTab => {
              if (!initialChecked[item.id][sub.sub_menu_id]) initialChecked[item.id][sub.sub_menu_id] = {};
              initialChecked[item.id][sub.sub_menu_id][subTab.tab_menu_id] = ensureAllPermissions(subTab.sub_tab_menu_user_permission_list);
            });
          });
        });
        setChecked(initialChecked);
      }
    } catch (error) {
    }
  };

  const convertBooleanToNumber = (obj) => {
    const newObj = {};
    for (const key in obj) {
      newObj[key] = obj[key] ? 1 : 0;
    }
    return newObj;
  };

  const ensureAllPermissions = (permissions) => {
    const allPermissions = {
      is_add: 0,
      is_view: 0,
      is_export: 0,
      is_delete: 0,
      is_edit: 0,
    };

    for (const key in permissions) {
      if (permissions.hasOwnProperty(key)) {
        allPermissions[key] = permissions[key];
      }
    }

    return convertBooleanToNumber(allPermissions);
  };


  return (
    <div className={`Sidebar-left ${DynmicClass}`}>
      <ul>

        {userinfo?.user_type == "COMPANY" &&
          <>
            <li>
              <NavLink to={`/${userinfo?.company_slug}/dashboard`} className={({ isActive }) => (isActive ? "active" : undefined)}>Home</NavLink>
            </li>
            <li>
              <NavLink to={`/${userinfo?.company_slug}/employee`} className={({ isActive }) => (isActive ? "active" : undefined)}>Employees</NavLink>
            </li>


            <li>
              <NavLink to={`/${userinfo?.company_slug}/prospects`} className={({ isActive }) => (isActive ? "active" : undefined)}>Prospects</NavLink>
            </li>

            <li>
              <NavLink to={`/${userinfo?.company_slug}/customers`} className={({ isActive }) => (isActive ? "active" : undefined)}>Customers</NavLink>
            </li>

            <li>
              <NavLink to={`/${userinfo?.company_slug}/guardians`} className={({ isActive }) => (isActive ? "active" : undefined)}>Guardians</NavLink>
            </li>

            <li>
              <NavLink to={`/${userinfo?.company_slug}/students`} className={({ isActive }) => (isActive ? "active" : undefined)}>Student</NavLink>
            </li>
            <li>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Curriculum </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li> <NavLink to={`/${userinfo?.company_slug}/curriculum/group`} className={({ isActive }) => (isActive ? "active" : undefined)} style={{fontSize: '16px'}}>Group</NavLink></li>
                      <li> <NavLink to={`/${userinfo?.company_slug}/curriculum/grade`} className={({ isActive }) => (isActive ? "active" : undefined)} style={{fontSize: '16px'}}>Grade</NavLink></li>
                      <li> <NavLink to={`/${userinfo?.company_slug}/curriculum/subject`} className={({ isActive }) => (isActive ? "active" : undefined)} style={{fontSize: '16px'}}>Subject</NavLink></li>
                      <li> <NavLink to={`/${userinfo?.company_slug}/curriculum/syllabus`} className={({ isActive }) => (isActive ? "active" : undefined)} style={{fontSize: '16px'}}>Syllabus</NavLink></li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

              </Accordion>

            </li>

            <li>
              <NavLink to={`/${userinfo?.company_slug}/Test/Viewassessment`} className={({ isActive }) => (isActive ? "active" : undefined)}>Assessment</NavLink>
            </li>

            <li>
              <NavLink to={`/${userinfo?.company_slug}/attendance`} className={({ isActive }) => (isActive ? "active" : undefined)}>Attendance</NavLink>
            </li>

            <li>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Services & Pricing</Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li> <NavLink to={`/${userinfo?.company_slug}/ServiceList`} className={({ isActive }) => (isActive ? "active" : undefined)} style={{fontSize: '16px'}}>Services & Pricing Setup</NavLink></li>
                      <li> <NavLink to={`/${userinfo?.company_slug}/PurchaseRegistration`} className={({ isActive }) => (isActive ? "active" : undefined)} style={{fontSize: '16px'}}>Purchase Registration</NavLink></li>
                      <li> <NavLink to={`/${userinfo?.company_slug}/MembershipList`} className={({ isActive }) => (isActive ? "active" : undefined)} style={{fontSize: '16px'}}>Membership Setup</NavLink></li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>

              </Accordion>
            </li>
           
            <li>
              <NavLink to={`/${userinfo?.company_slug}/Test/Addschedule`} className={({ isActive }) => (isActive ? "active" : undefined)}>Schedule</NavLink>
            </li>
            {/* <li>
              <NavLink to="/company" className={({ isActive }) => (isActive ? "active" : undefined)}>Schedule</NavLink>
            </li> */}

            <li>
              <NavLink to={`/${userinfo?.company_slug}/location`} className={({ isActive }) => (isActive ? "active" : undefined)}>Locations</NavLink>
            </li>

            <li>
              <NavLink to={`/${userinfo?.company_slug}/Training/ViewTraining`} className={({ isActive }) => (isActive ? "active" : undefined)}>Training</NavLink>
            </li>

            <li>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Settings </Accordion.Header>
                  <Accordion.Body>
                    <ul>
                      <li> <NavLink to={`/${userinfo?.company_slug}/settings/Title_CP`} className={({ isActive }) => (isActive ? "active" : undefined)} style={{fontSize: '16px'}}>Title</NavLink></li>
                      <li> <NavLink to={`/${userinfo?.company_slug}/settings/BackgroundCheckCompany`} className={({ isActive }) => (isActive ? "active" : undefined)} style={{fontSize: '16px'}}>Background Check Company</NavLink></li>
                      {/* <li> <NavLink to={`/${userinfo?.company_slug}/settings/Module_Type`} className={({ isActive }) => (isActive ? "active" : undefined)} style={{fontSize: '16px'}}>Lead Interest</NavLink></li> */}

                      <li> <NavLink to={`/${userinfo?.company_slug}/settings/LeadInterest`} className={({ isActive }) => (isActive ? "active" : undefined)} style={{fontSize: '16px'}}>Prospect Interest</NavLink></li>
                      <li> <NavLink to={`/${userinfo?.company_slug}/settings/LeadSource`} className={({ isActive }) => (isActive ? "active" : undefined)} style={{fontSize: '16px'}}>Prospect Source</NavLink></li>

                      <li> <NavLink to={`/${userinfo?.company_slug}/settings/MyProfile`} className={({ isActive }) => (isActive ? "active" : undefined)} style={{fontSize: '16px'}}>Profile</NavLink></li>
                      <li> <NavLink to={`/${userinfo?.company_slug}/settings/ViewEmailTemplate`} className={({ isActive }) => (isActive ? "active" : undefined)} style={{fontSize: '16px'}}>Message Template</NavLink></li>
                      <li> <NavLink to={`/${userinfo?.company_slug}/settings/RolePermission/AddRolePermission`} className={({ isActive }) => (isActive ? "active" : undefined)} style={{fontSize: '16px'}}>Role Permission</NavLink></li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </li>

            <li>
              <NavLink to={`/${userinfo?.company_slug}/Report/ReportList`} className={({ isActive }) => (isActive ? "active" : undefined)}>Reports</NavLink>
            </li>

            <li>
              <NavLink to="/company" className={({ isActive }) => (isActive ? "active" : undefined)}>Payment</NavLink>
            </li>
            <li>
              <NavLink to="/company" className={({ isActive }) => (isActive ? "active" : undefined)}>Tasks</NavLink>
            </li>
            <li className='mb-4'>
              <NavLink to="/company" className={({ isActive }) => (isActive ? "active" : undefined)}>Documents</NavLink>
            </li>

          </>
        }
        {userinfo?.user_type == "EMPLOYEE" &&
          <>

          {/* {Sidemenus?.[0]?.menu_user_permission_list?.is_view === 1 && (
            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/dashboard`} className={({ isActive }) => (isActive ? "active" : undefined)}>Home</NavLink>
            </li>
          )} */}

          {Sidemenus?.[0]?.menu_user_permission_list?.is_view === 1 && (
            <li>
              <NavLink to={`/${userinfo?.company_slug}/employee/dashboard`} className={({ isActive }) => (isActive ? "active" : undefined)}>Home</NavLink>
            </li>
          )}

            <li>
              <NavLink to={`/${userinfo?.company_slug}/employee`} className={({ isActive }) => (isActive ? "active" : undefined)}>Employees</NavLink>
            </li>

            <li>
              <NavLink to={`/${userinfo?.company_slug}/employee-app/my-attendence`} className={({ isActive }) => (isActive ? "active" : undefined)}>My Attendence</NavLink>
            </li>
            <li>
              <NavLink to={`/${userinfo?.company_slug}/prospects`} className={({ isActive }) => (isActive ? "active" : undefined)}>Prospects</NavLink>
            </li>

            <li>
              <NavLink to={`/${userinfo?.company_slug}/customers`} className={({ isActive }) => (isActive ? "active" : undefined)}>Customers</NavLink>
            </li>

            <li>
              <NavLink to={`/${userinfo?.company_slug}/guardians`} className={({ isActive }) => (isActive ? "active" : undefined)}>Guardians</NavLink>
            </li>

            <li>
              <NavLink to={`/${userinfo?.company_slug}/students`} className={({ isActive }) => (isActive ? "active" : undefined)}>Student</NavLink>
            </li>
          </>
        }
        {userinfo?.user_type == "PROSPECT" &&
        <>
            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/dashboard`} className={({ isActive }) => (isActive ? "active" : undefined)}>Home</NavLink>
            </li>
            
            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/guardian/studentList`} className={({ isActive }) => (isActive ? "active" : undefined)}>Student</NavLink>
            </li>

            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/guardian/GuardianTrailSchedule`} className={({ isActive }) => (isActive ? "active" : undefined)}>Schedule a Trial Session </NavLink>
            </li>

            <li>
            <NavLink to={`/${userinfo?.company_slug}/application/guardian/studentSchedule`} className={({ isActive }) => (isActive ? "active" : undefined)}>Schedule a New Session </NavLink>
            </li>

            <li>
            <NavLink to={`/${userinfo?.company_slug}/application/guardian/scheduleHelpdesk`} className={({ isActive }) => (isActive ? "active" : undefined)}>Schedule a PC Helpdesk </NavLink>
            </li>

            {/* <li>
              <NavLink to={`/${userinfo?.company_slug}/application/guardian/scheduledSession`} className={({ isActive }) => (isActive ? "active" : undefined)}>Scheduled Session</NavLink>
            </li> */}

            {/* <li>
              <NavLink to={`/${userinfo?.company_slug}/application/guardian/studentAttendance`} className={({ isActive }) => (isActive ? "active" : undefined)}>Attendance</NavLink>
            </li>

            <li>
              <NavLink className={({ isActive }) => (isActive ? "active" : undefined)}>My Homeworks</NavLink>
            </li>

            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/guardian/studentAssessment`} className={({ isActive }) => (isActive ? "active" : undefined)}>My Tests</NavLink>
            </li>
            
            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/guardian/studentEnrollment`} className={({ isActive }) => (isActive ? "active" : undefined)}>My Subscriptions</NavLink>
            </li>

            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/guardian/studentDocuments`} className={({ isActive }) => (isActive ? "active" : undefined)}>Documents</NavLink>
            </li>

            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/guardian/studentActivities`} className={({ isActive }) => (isActive ? "active" : undefined)}>Activities</NavLink>
            </li>

            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/guardian/studentTeacher`} className={({ isActive }) => (isActive ? "active" : undefined)}>Teachers</NavLink>
            </li>

            <li>
              <NavLink className={({ isActive }) => (isActive ? "active" : undefined)}>Rewards</NavLink>
            </li> */}
            
            {/* <li>
              <NavLink to="/company" className={({ isActive }) => (isActive ? "active" : undefined)}>Progress Reports</NavLink>
            </li>

            <li>
              <NavLink to="/company" className={({ isActive }) => (isActive ? "active" : undefined)}>Library</NavLink>
            </li>

            <li>
              <NavLink to="/company" className={({ isActive }) => (isActive ? "active" : undefined)}>Refer a Friend</NavLink>
            </li>
            
            <li>
              <NavLink to="/company" className={({ isActive }) => (isActive ? "active" : undefined)}>Chat with us</NavLink>
            </li> */}

          </>
        }
        {userinfo?.user_type == "GUARDIAN" &&
          <>
            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/dashboard`} className={({ isActive }) => (isActive ? "active" : undefined)}>Home</NavLink>
            </li>
            
            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/guardian/studentList`} className={({ isActive }) => (isActive ? "active" : undefined)}>Student</NavLink>
            </li>

            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/guardian/GuardianTrailSchedule`} className={({ isActive }) => (isActive ? "active" : undefined)}>Schedule a Trial Session </NavLink>
            </li>

            <li>
            <NavLink to={`/${userinfo?.company_slug}/application/guardian/studentSchedule`} className={({ isActive }) => (isActive ? "active" : undefined)}>Schedule a New Session </NavLink>
            </li>

            <li>
            <NavLink to={`/${userinfo?.company_slug}/application/guardian/scheduleHelpdesk`} className={({ isActive }) => (isActive ? "active" : undefined)}>Schedule a PC Helpdesk </NavLink>
            </li>

            {/* <li>
              <NavLink to={`/${userinfo?.company_slug}/application/guardian/scheduledSession`} className={({ isActive }) => (isActive ? "active" : undefined)}>Scheduled Session</NavLink>
            </li> */}

            {/* <li>
              <NavLink to={`/${userinfo?.company_slug}/application/guardian/studentAttendance`} className={({ isActive }) => (isActive ? "active" : undefined)}>Attendance</NavLink>
            </li>

            <li>
              <NavLink className={({ isActive }) => (isActive ? "active" : undefined)}>My Homeworks</NavLink>
            </li>

            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/guardian/studentAssessment`} className={({ isActive }) => (isActive ? "active" : undefined)}>My Tests</NavLink>
            </li>
            
            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/guardian/studentEnrollment`} className={({ isActive }) => (isActive ? "active" : undefined)}>My Subscriptions</NavLink>
            </li>

            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/guardian/studentDocuments`} className={({ isActive }) => (isActive ? "active" : undefined)}>Documents</NavLink>
            </li>

            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/guardian/studentActivities`} className={({ isActive }) => (isActive ? "active" : undefined)}>Activities</NavLink>
            </li>

            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/guardian/studentTeacher`} className={({ isActive }) => (isActive ? "active" : undefined)}>Teachers</NavLink>
            </li>

            <li>
              <NavLink className={({ isActive }) => (isActive ? "active" : undefined)}>Rewards</NavLink>
            </li> */}
            
            <li>
              <NavLink to="/company" className={({ isActive }) => (isActive ? "active" : undefined)}>Progress Reports</NavLink>
            </li>

            <li>
              <NavLink to="/company" className={({ isActive }) => (isActive ? "active" : undefined)}>Library</NavLink>
            </li>

            <li>
              <NavLink to="/company" className={({ isActive }) => (isActive ? "active" : undefined)}>Refer a Friend</NavLink>
            </li>
            
            <li>
              <NavLink to="/company" className={({ isActive }) => (isActive ? "active" : undefined)}>Chat with us</NavLink>
            </li>

          </>
        }
        {userinfo?.user_type == "STUDENT" &&
          <>
          
          {Sidemenus?.[0]?.menu_user_permission_list?.is_view === 1 && (
            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/dashboard`} className={({ isActive }) => (isActive ? "active" : undefined)}>Home</NavLink>
            </li>
          )}
          {Sidemenus?.[4]?.tab_menu_list?.[0]?.tab_menu_user_permission_list?.is_view === 1 && (
            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/student/assesment`} className={({ isActive }) => (isActive ? "active" : undefined)}>Assessment List</NavLink>
            </li>
          )}
          {Sidemenus?.[4]?.tab_menu_list?.[1]?.tab_menu_user_permission_list?.is_view === 1 && (
            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/student/StudentServiceList`} className={({ isActive }) => (isActive ? "active" : undefined)}>Service List</NavLink>
            </li>
          )}
          {/* {Sidemenus?.[11]?.menu_user_permission_list?.is_view === 1 && ( */}
            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/student/ScheduleList`} className={({ isActive }) => (isActive ? "active" : undefined)}>Schedule</NavLink>
            </li>
          {/* )} */}
          {/* {Sidemenus?.[4]?.tab_menu_list?.[1]?.tab_menu_user_permission_list?.is_view === 1 && ( */}
            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/student/StudentAttendance`} className={({ isActive }) => (isActive ? "active" : undefined)}>Attendance</NavLink>
            </li>
          {/* )} */}

            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/student/Homeworks`}  className={({ isActive }) => (isActive ? "active" : undefined)}>My Homeworks</NavLink>
            </li>
            {Sidemenus?.[4]?.tab_menu_list?.[4]?.tab_menu_user_permission_list?.is_view === 1 && (
            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/student/StudentDocument`} className={({ isActive }) => (isActive ? "active" : undefined)}>Documents</NavLink>
            </li>
            )}

            {/* <li>
              <NavLink to={`/${userinfo?.company_slug}/application/student/StudentActivities`} className={({ isActive }) => (isActive ? "active" : undefined)}>Activities</NavLink>
            </li>

            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/student/StudentTeachers`} className={({ isActive }) => (isActive ? "active" : undefined)}>Teachers</NavLink>
            </li>

            <li>
              <NavLink to={`/${userinfo?.company_slug}/application/student/StudentRewards`} className={({ isActive }) => (isActive ? "active" : undefined)}>Rewards</NavLink>
            </li> */}
            
            {/* <li>
              <NavLink className={({ isActive }) => (isActive ? "active" : undefined)}>Progress Reports</NavLink>
            </li>

            <li>
              <NavLink className={({ isActive }) => (isActive ? "active" : undefined)}>Library</NavLink>
            </li>

            <li>
              <NavLink className={({ isActive }) => (isActive ? "active" : undefined)}>Refer a Friend</NavLink>
            </li>
            
            <li>
              <NavLink className={({ isActive }) => (isActive ? "active" : undefined)}>Chat with us</NavLink>
            </li> */}

          </>
        }

{/* <Route path={`/${userinfo?.company_slug}/application/student/scheduleList/:id`} element={<ScheduleList />} /> */}

      </ul>
    </div>
  )
}

export default CompanySidebar